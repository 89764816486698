/* 
div.dropdown > a.label {
    background-color: lawngreen;
} */

/* .ui.multiple.dropdown > .label {
    background-color: lightblue;
} */

.ui.search>.results {
    max-height: 800px;
    overflow-y: scroll;
}