.dashboard-games .game {
    margin-bottom: 1em;
}
.dashboard-games .game {
    background-color: #FFF;
    border: 3px solid #DCDBD9;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.25);
    margin-bottom: 1.5em;
}
.dashboard-games .game {
    background-color: #383D48;
    border: 3px solid #7FBF90;
}
.dashboard-games.recent .game {
    border: 3px solid #FFF;
}
.dashboard-games .ui.grid>.row {
    padding-top: 0;
}
.dashboard-games .ui.grid>.row .three {
    padding-top: 1rem;
}
.dashboard-games .ui.grid>.row .column {
    box-shadow: -1px 0 0 0 rgb(34 36 38 / 35%);
    -webkit-box-shadow: -1px 0 0 0 rgb(34 36 38 / 35%);
    -moz-box-shadow: -1px 0 0 0 rgb(34 36 38 / 35%);
}
.games-content .ui.game > div.game-content {
    text-align: center;
    padding-top: 0;
}
.games-content .ui.game.trotting.open > div.game-content {
    padding-top: 1rem;
}
.game .data-holder {
    color: #AAA;
}
.game .data-holder span,
.game .data-holder div{
    color: #888;
    font-weight: bold;
}
.game .data-holder {
    color: #CCC;
}
.game .data-holder span,
.game .data-holder div{
    color: #FFF;
}
.game .extraction .data-holder span {
    font-weight: normal;
}
.game .data-holder .data-label {
    color: #ccc;
    font-weight: normal;
}
.league-title {
    color: #888;
    font-size: 1rem;
}
.dashboard-games .games-content {
    padding: .5em 1em;
}
.dashboard-games .games-content a,
.dashboard-games .games-content a:visited {
    color: #222;
}
.dashboard-games .games-content,
.dashboard-games .games-content a,
.dashboard-games .games-content a:visited {
    color: #FFF;
}
.dashboard-games .games-content a.game:hover,
.dashboard-games .games-content a.game:focus {
    color: rgba(0,0,0,0.5);
}
.dashboard-games .games-content a.game:hover,
.dashboard-games .games-content a.game:focus {
    color: rgba(255,255,255,0.5);
}
.dashboard-games .game .game-clock {
    background-color: #222;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 2rem;
    margin-bottom: 1rem;
    padding: .75rem;
    text-align: center;
}
.dashboard-games .game .kick-off {
    background-color: #222;
    border-radius: 0 0 5px 5px;
    display: inline-block;
    padding: .75rem;
}
.dashboard-games .game .ui.grid>.row.teams .column {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}
.dashboard-games .game .row.teams .two {
    font-size: 2rem;
    margin-top: 1em;
}
.dashboard-games .game .row .star {
    cursor: pointer;
    line-height: 1.25rem;
    font-size: 1rem;
    position: absolute;
    left: 15px;
    top: -11px;
}
.dashboard-games .game.trotting.open .row .star {
    top: 16px;
}
.dashboard-games.stared .game .row .star {
    color: #5CAC71;
}

.dashboard-games .game h4.team {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 1em 0 .5em;
    text-transform: uppercase;
}
.dashboard-games .game a h4.team,
.dashboard-games .game a:visited h4.team {
    border-bottom: 1px dashed transparent;
    color: #7FBF90;
    display: inline-block;
}
.dashboard-games .game a:focus h4.team,
.dashboard-games .game a:hover h4.team {
    border-bottom: 1px dashed #7FBF90;
}
.game .game-clock {
    overflow: auto;
}
.game .game-clock .clock {
    border-right: 1px solid #000;
    float: left;
    width: 33%;
}
.game .game-time {
    height: 30px;
}
.game .time {
    line-height: 3rem;
}
.game .phase {
    font-size: 1rem;
    margin-top: .25em;
}
.game .distance {
    margin-bottom: 1em;
}
.game .status {
    margin-top: .3em;
}
.game .stadium {
    margin-bottom: .5em;
    position: relative;
}
.game .sport-icon {
    left: 12px;
    position: absolute;
    top: 11px;
}
.game.open .sport-icon {
    display: none;
}
.game .pitch-icon {
    left: 1px;
    margin-right: .5em;
    position: absolute;
    top: 2px;
    width: 25px;
}
.game .stadium span {
    padding-left: 32px;
}
.last-frame {
    padding-left: 15px;
    position: relative;
}
.receiving .last-frame::after,
.stopped .last-frame::after {
    border-radius: 5px;
    content: "";
    display: block;
    height: 10px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 10px;
}
.receiving .last-frame::after {
    background: #5bc277;
    border: 1px solid #42e36c;
    box-shadow: 0 0 0 rgba(66,227,108, 0.4);
    animation: pulse 1s infinite;
}
.stopped .last-frame::after {
    background: #666;
    border: 1px solid #9f9f9f;
}
/* Collapsing */
.icon-wrapper {
    text-align: right;
}
.game.collapsed .stadium,
.game.collapsed .distance,
.game.collapsed .extraction,
.game.collapsed .game-clock img,
.game.collapsed .column .data-label,
.game.collapsed .last-column .data-holder,
.game.collapsed .kick-off,
.game.collapsed .event-section,
.game.collapsed .skeleton-frames {
    display: none;
}
.game.collapsed .clock {
    width: auto;
}
.game.collapsed .phase {
    font-size: 1rem;
    margin-right: 5px;
}
.game.collapsed .time-phase {
    float: left;
    margin-left: 5px;
}
.game.collapsed .time {
    line-height: 2rem;
    font-size: 1rem;
}
.game.collapsed .game-clock {
    float: left;
    margin-bottom: 0;
    margin-left: 35px;
    padding: .25rem;
}
.game.collapsed .last-frame {
    float: left;
    margin-top: 0.5em;
    margin-left: 2.25em;
}
.dashboard-games .game.collapsed .row.teams .two {
    margin-top: .5em;
}
.dashboard-games .game.collapsed .row,
.game.collapsed.ui.grid>.column:not(.row) {
    padding-bottom: .5em;
    padding-top: .5em;
}
.dashboard-games .game.collapsed .row .star {
    top: 16px;
}
i.collapse-icon {
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: 7px;
}
.game.open .collapse-icon {
    -webkit-transform: scale(1,-1);
    transform: scale(1,-1);
}
.race-day {
    color: #333;
    margin-bottom: 1.5em;
}
.race-day h3 {
    display: inline-block;
    margin: 0 0 0 10px;
}
.race-day .event-section .data-holder div {
    font-weight: bold;
}

/* Responsive tweaks */
@media (min-width: 768px) and (max-width: 1200px) {
    .game .game-clock .clock {
      border-right: none;
      width: 100%
    }
    .game-clock .time-phase .time {
      font-size: 1.25rem;
      padding-left: 10px;
    }
}
@media (max-width: 767px) {
    .games-content .ui.stackable.grid.game .row {
      padding: 0 1em;
    }
    .game .column-content {
        padding: 0 1em;
    }
}
@media (max-width: 767px) {
    .dashboard-games .game.collapsed .row .star {
        top: -10px;
    }
}
