.empty-message {
    width: 100%;
    margin-bottom: 1em;
}
.dashboard-games h2 {
    color: #333;
    font-size: 1rem;
    padding: 0 0 0.5em;
    border-bottom: 1px dashed #ccc;
    margin-bottom: 1.5em;
}
.dashboard-games.live {
    border-top: 1px dashed #ccc;
    padding-top: 1.5rem;
}
.ui.progress {
    border-radius: 0;
}
.ui.progress .bar {
    background-color: #7FBF90;
    border-radius: 0;
    transition: width 1s linear, background-color .1s linear;
    -webkit-transition: width 1s linear, background-color .1s linear;
    -moz-transition:width 1s linear, background-color .1s linear;
    -o-transition:width 1s linear, background-color .1s linear;
    -ms-transition:width 1s linear, background-color .1s linear;
}
.soccer-hidden,
.trotting-hidden {
    display: none;
}
.soccer-visible,
.trotting-visible {
    display: block;
}
.trotting-visible {
    background-color: #E1E1E1;
    border-radius: 8px;
    margin: 0 -10px 1.5em;
    padding: 1em 2em;
}
