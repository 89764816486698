body {
  background-color: #F5F4F0;
  color: #222;
}

body a {
  transition: .5s;
}

.App {
  text-align: center;
}

.App-logo {
  max-height: 40px;
}

.App-header {
  background-color: #333740;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ui.menu.inverted {
  background: rgb(51,55,64);
  background: linear-gradient(90deg, rgba(51,55,64,1) 0%, rgba(67,74,90,1) 100%);
}
.ui.menu.inverted .item a,
.ui.menu.inverted a.item,
.ui.menu.inverted a.item:visited,
.ui.menu.inverted .item a:visited {
  color: #5CAC71;
}

.ui.menu.inverted .item.active {
  background: rgba(0,0,0,0.2);
  box-shadow: inset 0 -7px 0 #5CAC71;
  -webkit-box-shadow: inset 0 -7px 0 #5CAC71;
  -moz-box-shadow: inset 0 -7px 0 #5CAC71;
  color: #5CAC71!important; /* Override semantic ui react... */
}

.ui.menu.inverted .item.active:hover {
  color: #5CAC71!important; /* Override semantic ui react... */
}

/*.ui.menu.inverted .item.active {
  background-color: #2E323A;
}*/

.ui.menu.inverted .item a:hover,
.ui.menu.inverted a.item:hover,
.ui.menu.inverted .item a:focus {
  color: #5CAC71;
}

.App-link {
  color: #61dafb;
}

.ui.button {
  border-radius: 1.5em;
}

.ui.slider.checkbox {
  padding-top: 3px;
}

.ui.slider.checkbox .box:after,
.ui.slider.checkbox label:after {
    background-color: lightcoral;
}

.ui.slider.checkbox input:checked~.box:after,
.ui.slider.checkbox input:checked~label:after {
    background-color: palegreen;
}

.ui.menu .item:before {
  width: 0;
}

.page-content {
  padding-top: 4.8em;
}

.button.cta {
  background-color: #7FBF90;
  color: #FFF;
}

.filter-form .checkbox {
  padding-top: 8px;
}

.table.ui {
    border-top: .2em solid #5CAC71;
}
.error {
    color: rgb(218,72,72);
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(66,227,108, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(66,227,108, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(66,227,108, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(66,227,108, 0.4);
        box-shadow: 0 0 0 0 rgba(66,227,108, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(66,227,108, 0);
        box-shadow: 0 0 0 10px rgba(66,227,108, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(66,227,108, 0);
        box-shadow: 0 0 0 0 rgba(66,227,108, 0);
    }
}

/* Responsive Menu */
.menu-icon {
  display: none;
  width: 50px;
  height: 41px;
  position: relative;
  margin: 1em;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.menu-icon span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: #5CAC71;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.menu-icon span:nth-child(1) {
  top: 0px;
}
.menu-icon span:nth-child(2) {
  top: 18px;
}
.menu-icon span:nth-child(3) {
  top: 36px;
}

.menu-icon.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.menu-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.menu-icon.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

@media (max-width: 767px) {
  .menu-icon {
    display: block;
  }
  .ui.menu.inverted .item,
  .ui.menu.inverted .right.item {
    display: none;
  }
  .ui.menu.inverted .item.link-open,
  .ui.menu.inverted .right.item.link-open {
    display: block;
  }
  .ui.menu.inverted .button {
    background: transparent;
    color: #fff;
    padding: 0;
  }
  .ui.menu.inverted .item.active {
    box-shadow: inset 0 -1px 0 #5CAC71;
    -webkit-box-shadow: inset 0 -1px 0 #5CAC71;
    -moz-box-shadow: inset 0 -1px 0 #5CAC71;
  }
}
