.game-filter {
    margin-bottom: 1rem;
}
.ui.filter-button {
    border: 1px solid #aaa;
}
.ui.filter-button.inactive {
    background-color: #d3d3d3;
}
.ui.filter-button.active {
    background-color: #fff;
    border: 1px solid #7FBF90
}
.ui.filter-button.active img {
    filter: invert(60%) sepia(3%) saturate(5361%) hue-rotate(83deg) brightness(101%) contrast(83%);
}
.ui.filter-button.active div {
    color: #5CAC71;
}
